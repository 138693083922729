import * as yup from 'yup';
import { __ } from 'utils/form';
import { validZipCodes } from 'constants/variables';

const validationSchema = yup.object().shape({
  full_address: yup
    .string()
    .nullable()
    .required(__('errors.full_address')),
  zip: yup
    .string()
    .nullable()
    .required(__('errors.fill_field'))
    .when('country', (val, schema) =>
      validZipCodes[val] ? schema.matches(validZipCodes[val], { message: __('errors.wrong_zip_code') }) : schema,
    ),
  avg_property_price_sq_m: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: false,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  estimated_property_price: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  land_area: yup
    .number()
    .required(__('errors.fill_field'))
    .min(200, __('errors.values_within_range', { min: 200, max: 3000 }))
    .max(3000, __('errors.values_within_range', { min: 200, max: 3000 })),
});

export default validationSchema;
