/* eslint-disable no-param-reassign */
import { Small } from 'components/common/heap';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from '../../utils/helpers';

const MonthlyExpensesInfo = ({ budget }) => {
  const { formatMessage: t } = useIntl();

  const {
    monthly_expenses,
    monthly_expenses_partner,
    monthly_expenses_kids,
    costs_for_repair,
    technology,
    luxuries,
    socializing,
    hobbies,
    taking_a_break,
    new_car,
    pension_and_insurances,
  } = Object.entries(budget.calculation).reduce((res, [key, value]) => {
    if (!value) return res;
    const { user_input, calculated_value } = value;
    return {
      ...res,
      [key]: formatCurrency(Number.isFinite(user_input) ? user_input : calculated_value),
    };
  }, {});

  return (
    <table className="table last-col-text-right">
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ defaultMessage: ' ', id: 'budget.titles.monthly_expenses' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        {budget.formData.amount_of_alimony ? (
          <tr>
            <td>{t({ defaultMessage: ' ', id: 'budget.monthly_alimony' })} </td>
            <td>{formatCurrency(budget.formData.amount_of_alimony)}</td>
          </tr>
        ) : null}
        {budget.formData.monthly_loan ? (
          <tr>
            <td>{t({ defaultMessage: ' ', id: 'budget.monthly_repayments_loans' })}</td>
            <td>{formatCurrency(budget.formData.monthly_loan)}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.monthly_expenses' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.monthly_expenses_description' })}</Small>
          </td>
          <td>{monthly_expenses}</td>
        </tr>
        {budget.formData.alone_or_partner === 'partner' ? (
          <tr>
            <td>
              {t({ defaultMessage: ' ', id: 'budget.monthly_expenses_partner' })}
              <Small>{t({ defaultMessage: ' ', id: 'budget.monthly_expenses_partner_description' })}</Small>
            </td>
            <td>{monthly_expenses_partner}</td>
          </tr>
        ) : null}
        {budget.formData.kids_quantity ? (
          <tr>
            <td>
              {t({ defaultMessage: ' ', id: 'budget.monthly_expenses_children' })}
              <Small>{t({ defaultMessage: ' ', id: 'budget.monthly_expenses_children_description' })}</Small>
            </td>
            <td>{monthly_expenses_kids}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.operational_costs_home' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.operational_costs_home_description' })}</Small>
          </td>
          <td>{costs_for_repair}</td>
        </tr>
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.socializing' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.socializing_description' })}</Small>
          </td>
          <td>{socializing}</td>
        </tr>
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.luxuries' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.luxuries_description' })}</Small>
          </td>
          <td>{luxuries}</td>
        </tr>
        {budget.formData.cars ? (
          <tr>
            <td>
              {t({ defaultMessage: ' ', id: 'budget.cars' }, { number: budget.formData.cars })}{' '}
              <Small>{t({ defaultMessage: ' ', id: 'budget.cars_description' })}</Small>
            </td>
            <td>{new_car}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.taking_a_break' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.taking_a_break_description' })}</Small>
          </td>
          <td>{taking_a_break}</td>
        </tr>
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.hobbies' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.hobbies_description' })}</Small>
          </td>
          <td>{hobbies}</td>
        </tr>
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.technology' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.technology_description' })}</Small>
          </td>
          <td>{technology}</td>
        </tr>
        <tr>
          <td>
            {t({ defaultMessage: ' ', id: 'budget.private_pension_and_insurances' })}
            <Small>{t({ defaultMessage: ' ', id: 'budget.private_pension_and_insurances_description' })}</Small>
          </td>
          <td>{pension_and_insurances}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>{t({ defaultMessage: ' ', id: 'budget.titles.total_monthly_costs' })}</td>
          <td>{formatCurrency(budget.calculation.monthly_costs.calculated_value)}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default MonthlyExpensesInfo;
