import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { formatGraphqlErrors } from 'utils/helpers';
import compact from 'lodash/compact';
import { useIntl } from 'react-intl';
import { BUDGET_CALCULATION_FRAGMENT, BUDGET_FORM_DATA_FRAGMENT } from 'graphql/fragments/budget';
import { FINANCING_QUERY } from '../financing/useFinancing';

const BUDGET_CALCULATOR_MUTATION = gql`
  mutation budgetCalculator($projectId: ID, $formData: BudgetCalculatorInput!, $locale: String) {
    budgetCalculator(projectId: $projectId, formData: $formData) {
      id
      negativeMonthlyRateLoan
      budgetEstimation {
        _id
        pdf(locale: $locale)
        finished
        ...budgetCalculation
        ...budgetFormData
      }
    }
  }
  ${BUDGET_CALCULATION_FRAGMENT}
  ${BUDGET_FORM_DATA_FRAGMENT}
`;

const useBudgetCalculator = () => {
  const { locale } = useIntl();
  const [budgetCalculatorMutation] = useMutation(BUDGET_CALCULATOR_MUTATION);
  const { id: projectId } = useParams();

  const budgetCalculator = useCallback(
    async (formData) => {
      try {
        const refetchQueries = [projectId ? { query: FINANCING_QUERY, variables: { id: projectId } } : null];

        const {
          data: { budgetCalculator: result },
        } = await budgetCalculatorMutation({
          variables: { formData, locale, ...(projectId ? { projectId } : {}) },
          refetchQueries: compact(refetchQueries),
          awaitRefetchQueries: true,
        });

        return result;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [budgetCalculatorMutation, locale, projectId],
  );
  return budgetCalculator;
};

export default useBudgetCalculator;
