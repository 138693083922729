import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import useSignUp from 'hooks/auth/useSignUp';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import LoginForm from 'components/auth/LoginForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { trackRegistration } from 'utils/analytics';
import get from 'lodash/get';
import { useModal } from 'components/common/Modal';
import routePaths, { routePatterns } from 'router/route-paths';
import { QuickCalculatorStep1 } from 'assets/icons/QuickCalculator';
import s from './SignUp.module.scss';

const emptyFunc = () => {};

export default function() {
  const [isSignUp, setIsSignUp] = useState(true);
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const signUp = useSignUp();
  const login = useLogin();
  const auth = isSignUp ? signUp : login;
  const handleSubmit = useAuthFormSubmit(auth, { onSuccess: trackRegistration });
  const { showModal, hideModal, isVisible, modalProps } = useModal();
  const history = useHistory();

  const onSubmitAdminLogin = useCallback(
    async ({ otp, authFunc, dataForTwoFA, formikActions }) => {
      try {
        await authFunc({ ...dataForTwoFA, otp });
        history.push(projectId ? routePatterns.dashboard.stringify({ id: projectId }) : routePaths.myProjects);
      } catch (e) {
        formikActions.setErrors({ otpInvalid: e.message });
      }
    },
    [history, projectId],
  );

  const onError = useCallback(
    (error, authFunc, dataForTwoFA, formikActions) => {
      if (error.message === 'AUTH_OTP_REQUIRED_ERROR') {
        showModal({
          onSubmitAdminLogin: async ({ otp }) => {
            await onSubmitAdminLogin({ authFunc, dataForTwoFA, otp, formikActions });
          },
        });
      }
    },
    [onSubmitAdminLogin, showModal],
  );

  const onSubmit = useCallback(
    async (values, formikActions) => {
      await handleSubmit(values, formikActions, onError);
    },
    [handleSubmit, onError],
  );

  return (
    <Grid>
      <Row between="lg" center="md">
        <Col xs={12} md={8} lg={6} xl={5}>
          <PageTitle>
            <FormattedMessage id={isSignUp ? 'page_titles.sign_up' : 'page_titles.login'} />
          </PageTitle>
          <LoginForm
            onSubmitAdminLogin={get(modalProps, 'onSubmitAdminLogin', emptyFunc)}
            {...{ isSignUp, showModal, hideModal, isVisible, setIsSignUp, onSubmit }}
          />
        </Col>
        <Col md={0} lg={6} xl={5} className={s.secondCol} first="xs" last="lg">
          <QuickCalculatorStep1 width="100%" height="100%" title={t({ id: 'alt_text.auth_pages.sign_up' })} />
        </Col>
      </Row>
    </Grid>
  );
}
