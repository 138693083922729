/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { Grid } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import pick from 'lodash/pick';
import get from 'lodash/get';
import useFormValuesRef from 'hooks/useFormValuesRef';
import useBudgetCalculator from 'hooks/budget/useBudgetCalculator';
import PageTitle from 'components/common/PageTitle';
import client from 'graphql/apollo';
import { MY_BUDGET_QUERY } from 'hooks/budget/useMyBudget';
import { useHistory, useParams } from 'react-router-dom';
import routePaths, { routePatterns } from 'router/route-paths';
import StepWizard from 'react-step-wizard';
import Nav from 'components/calculators/Nav';
import { toast } from 'react-toastify';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import FifthStep from './FifthStep';
import FourthStep from './FourthStep';
import ThirdStep from './ThirdStep';

const formValueMock = {
  alone_or_partner: 'alone',
  amount_of_alimony: 0,
  cars: 1,
  kids_quantity: 2,
  monthly_net_salary: 6000,
  monthly_net_salary_partner: 0,
  monthly_loan: 100,
  other_revenues: 1000,
  square_meters: 160,
  project_type: 'new_apartment',
  new_car: 'important',
  luxuries: 'important',
  hobbies: 'not_important',
  socializing: 'not_important',
  pension_and_insurances: 'not_important',
  technology: 'very_important',
  taking_a_break: 'very_important',
  age: 30,
};

const formDataAllowedKeys = [
  'alone_or_partner',
  'kids_quantity',
  'monthly_net_salary',
  'monthly_net_salary_partner',
  'other_revenues',
  'cars',
  'amount_of_alimony',
  'monthly_loan',
  'project_type',
  'square_meters',
  'age',
];

const monthlyExpensesKeys = [
  'socializing',
  'luxuries',
  'new_car',
  'taking_a_break',
  'hobbies',
  'technology',
  'pension_and_insurances',
];

const useSubmitBudgetCalculator = (formValuesRef) => {
  const budgetCalculatorSubmit = useBudgetCalculator();
  const history = useHistory();
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();

  const onSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
      setSubmitting(true);

      try {
        const allowedFormValues = pick(formValuesRef.current, formDataAllowedKeys);
        const monthlyExpensesFields = pick(formValuesRef.current, monthlyExpensesKeys);

        const monthlyExpensesCategories = Object.keys(monthlyExpensesFields).reduce((acc, categoryName) => {
          const numValue = get(formValuesRef.current, `${categoryName}_number_value`, null);
          const value = {
            importanceValue: monthlyExpensesFields[categoryName],
            numberValue: numValue,
          };
          return { ...acc, [categoryName]: value };
        }, {});

        const budget = await budgetCalculatorSubmit({ ...allowedFormValues, ...monthlyExpensesCategories });

        client.writeQuery({ query: MY_BUDGET_QUERY, data: { myBudget: { ...budget.budgetEstimation } } });

        toast.success(t({ id: 'app.data_saved_successfully' }));
        if (!budget.budgetEstimation.finished) return;
        if (projectId) {
          history.push(routePatterns.lifestyleOverviewWithProject.stringify({ id: projectId }));
        } else {
          history.push(routePaths.lifestyleOverview);
        }
      } catch (error) {
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [budgetCalculatorSubmit, formValuesRef, history, projectId, t],
  );
  return onSubmit;
};

const tabs = [
  { label: 'budget_calculator.first_step.title', component: FirstStep },
  { label: 'budget_calculator.second_step.title', component: SecondStep },
  { label: 'budget_calculator.third_step.title', component: ThirdStep },
  { label: 'budget_calculator.fourth_step.title', component: FourthStep },
  { label: 'budget_calculator.fifth_step.title', component: FifthStep },
];

const LifestyleCalculatorForm = ({ initialValues, initialStep, finished }) => {
  const { formatMessage: t } = useIntl();
  const formValuesRef = useFormValuesRef([], { formValueMock, initialValues });
  const onSubmit = useSubmitBudgetCalculator(formValuesRef);

  const onSubmitStep = useCallback(
    (values) => {
      window.scrollTo(0, 0);
      formValuesRef.current = { ...formValuesRef.current, ...values };
    },
    [formValuesRef],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <Grid>
      <PageTitle>{t({ id: 'page_titles.budget' })}</PageTitle>

      <StepWizard
        isLazyMount
        transitions={{}}
        initialStep={initialStep}
        nav={<Nav {...{ tabs, isEditMode: finished }} />}
      >
        {tabs.map(({ label, component: C }) => (
          <C key={label} {...{ initialValues, formValuesRef, onSubmitStep, onSubmit, onBack, isEditMode: finished }} />
        ))}
      </StepWizard>
    </Grid>
  );
};

export default LifestyleCalculatorForm;
