export default {
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  isStaging: process.env.REACT_APP_IS_STAGING ? JSON.parse(process.env.REACT_APP_IS_STAGING) : false,
  debug: process.env.REACT_APP_DEBUG ? JSON.parse(process.env.REACT_APP_DEBUG) : false,
  apiUrl: process.env.REACT_APP_API_URL,
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE,
  termsOfUseUrl: process.env.REACT_APP_TERMS_OF_USE,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  ga: process.env.REACT_APP_GOOGLE_ANALYTICS,
  dsn: process.env.REACT_APP_DSN,
  googleApiKey: process.env.REACT_APP_GOOGLE_CONSOLE_API_KEY,
  cookiebotDomainGroupId: process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID,
  gtmId: process.env.REACT_APP_GTM_ID,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  enuPartnerLink: process.env.REACT_APP_ENU_PARTNER_URL,
};
