import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { formatGraphqlErrors } from 'utils/helpers';
import { get } from 'lodash';

const MONTHLY_EXPENSES_CATEGORY_VALUE = gql`
  mutation monthlyExpensesCategoryValue($categoryName: String!, $importanceValue: BudgetCalculatorImportance!) {
    monthlyExpensesCategoryValue(categoryName: $categoryName, importanceValue: $importanceValue) {
      categoryName
      categoryValue
    }
  }
`;

const useCalculateMonthlyExpensesCategoryValue = () => {
  const [calculateMonthlyExpensesCategoryValue] = useMutation(MONTHLY_EXPENSES_CATEGORY_VALUE);

  const handleCalculate = useCallback(
    async ({ categoryName, importanceValue }) => {
      try {
        const result = await calculateMonthlyExpensesCategoryValue({ variables: { categoryName, importanceValue } });
        const category = get(result, 'data.monthlyExpensesCategoryValue');
        return category;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [calculateMonthlyExpensesCategoryValue],
  );

  return handleCalculate;
};

export default useCalculateMonthlyExpensesCategoryValue;
