import React, { useMemo, useCallback, useState, useRef } from 'react';
import { BudgetStep5 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import * as yup from 'yup';
import ButtonSelect from 'components/inputs/ButtonSelect';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import { toast } from 'react-toastify';
import useCalculateMonthlyExpensesCategoryValue from 'hooks/budget/useCalculateMonthlyExpensesCategoryValue';
import NumberInput from 'components/inputs/NumberInput';
import s from './BudgetFormSteps.module.scss';

export const OPTIONS = [
  {
    label: 'budget_calculator.not_important_option_label',
    value: 'not_important',
  },
  {
    label: 'budget_calculator.important_option_label',
    value: 'important',
  },
  {
    label: 'budget_calculator.very_important_option_label',
    value: 'very_important',
  },
];

const validationSchema = yup.object().shape({
  socializing_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  luxuries_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  new_car_number_value: yup.mixed().when('cars', {
    is: (cars) => !!cars,
    then: yup
      .number()
      .required(__('errors.fill_field'))
      .typeError(__('errors.fill_field')),
  }),
  taking_a_break_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  hobbies_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  technology_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
  pension_and_insurances_number_value: yup
    .number()
    .required(__('errors.fill_field'))
    .typeError(__('errors.fill_field')),
});

const useMonthlyExpensesCategoryValueHandler = (formikRef) => {
  const calculateValue = useCalculateMonthlyExpensesCategoryValue();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = useCallback(
    async (name, value) => {
      setSubmitting(true);
      try {
        const category = await calculateValue({ categoryName: name, importanceValue: value });
        formikRef.current.setFieldValue(`${category.categoryName}_number_value`, category.categoryValue);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [calculateValue, formikRef],
  );
  return [handleChange, isSubmitting];
};

const CategoryGroup = ({ formikRef, inputName, inputLabel, buttonSelectName, buttonSelectDescription }) => {
  const { formatMessage: t } = useIntl();
  const [onImportanceValueChange, loading] = useMonthlyExpensesCategoryValueHandler(formikRef);
  const localizedOptions = useMemo(() => OPTIONS.map(({ label, ...rest }) => ({ label: t({ id: label }), ...rest })), [
    t,
  ]);

  const resetButtonSelection = useCallback(
    ({ name }) => {
      if (!formikRef?.current) return;
      const buttonSelectFieldName = name.split('_number_value')[0];
      formikRef.current.setFieldValue(buttonSelectFieldName, null);
    },
    [formikRef],
  );

  return (
    <>
      <Field name={inputName} label={inputLabel} component={NumberInput} sideEffectOnChange={resetButtonSelection} />
      <Field
        name={buttonSelectName}
        description={buttonSelectDescription}
        classnames="pt-0"
        options={localizedOptions}
        component={ButtonSelect}
        nullable={false}
        disabled={loading}
        skipScrollingToField
        sideEffectOnButtonClick={onImportanceValueChange}
      />
    </>
  );
};

const FifthStep = ({ initialValues, isEditMode, onSubmit, onBack, previousStep }) => {
  const { formatMessage: t } = useIntl();
  const formikRef = useRef();

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.fifth_step.title' })}</PageTitle>

        <Formik innerRef={formikRef} {...{ validationSchema, initialValues, onSubmit }}>
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />
              <CategoryGroup
                formikRef={formikRef}
                inputName="socializing_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.socializing' })}
                buttonSelectName="socializing"
                buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.socializing_descriptions' })}
              />
              <CategoryGroup
                formikRef={formikRef}
                inputName="luxuries_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.luxuries' })}
                buttonSelectName="luxuries"
                buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.luxuries_description' })}
              />

              {values.cars ? (
                <CategoryGroup
                  formikRef={formikRef}
                  inputName="new_car_number_value"
                  inputLabel={t({ id: 'budget_calculator.fifth_step.new_car' })}
                  buttonSelectName="new_car"
                  buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.new_car_description' })}
                />
              ) : null}

              <CategoryGroup
                formikRef={formikRef}
                inputName="taking_a_break_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.taking_a_break' })}
                buttonSelectName="taking_a_break"
                buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.taking_a_break_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="hobbies_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.hobbies' })}
                buttonSelectName="hobbies"
                buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.hobbies_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="technology_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.technology' })}
                buttonSelectName="technology"
                buttonSelectDescription={t({ id: 'budget_calculator.fifth_step.technology_description' })}
              />

              <CategoryGroup
                formikRef={formikRef}
                inputName="pension_and_insurances_number_value"
                inputLabel={t({ id: 'budget_calculator.fifth_step.pension_and_insurances' })}
                buttonSelectName="pension_and_insurances"
                buttonSelectDescription=""
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep5 title={t({ id: 'alt_text.budget_calculator.fifth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default FifthStep;
