/* eslint-disable no-console */
export const trackRegistration = () => {
  try {
    window.dataLayer.push({
      event: 'APP_REGISTRATION',
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackLogin = () => {
  try {
    window.dataLayer.push({
      event: 'APP_LOGIN',
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackResendVerificationEmail = () => {
  try {
    window.dataLayer.push({
      event: 'APP_RESEND_VERIFICATION_EMAIL',
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackUnverifiedLogout = () => {
  try {
    window.dataLayer.push({
      event: 'APP_UNVERIFIED_LOGOUT',
    });
  } catch (error) {
    console.log(error);
  }
};

export const trackQuickCalculatorSubmit = (formData) => {
  try {
    window.dataLayer.push({
      event: 'APP_QUICK_CALCULATOR_SUBMIT',
      attributes: {
        formData,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
