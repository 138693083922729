import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { PROPERTY_VALUATION } from 'constants/variables';
import useGetFilters from './useGetFilters';

export const PROJECTS_PAYERS_QUERY = gql`
  query projectsPayersQuery($filters: PaymentFilters, $excludeProjectTypes: [ProjectType!]) {
    paymentsTotalByPayers(filters: $filters) {
      payer {
        _id
        profile {
          name
          avatar {
            url
            file_name
          }
        }
      }
      total
    }
    projects(excludeProjectTypes: $excludeProjectTypes) {
      _id
      name
      payments_total
    }
  }
`;

const useProjectsPayersQuery = () => {
  const { filters } = useGetFilters();
  const { data, loading, refetch } = useQuery(PROJECTS_PAYERS_QUERY, {
    variables: { filters, excludeProjectTypes: [PROPERTY_VALUATION] },
  });

  const paymentsTotalByPayers = useMemo(() => get(data, 'paymentsTotalByPayers', []), [data]);
  const projects = useMemo(() => get(data, 'projects', []), [data]);

  return { paymentsTotalByPayers, projects, loading, refetch };
};

export default useProjectsPayersQuery;
