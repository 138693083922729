/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ReactComponent as OutlineQuestionCircle } from 'assets/icons/outline-question-circle.svg';
import { useIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-flexbox-grid';
import cn from 'classnames';
import Modal, { useModal } from '../Modal';
import s from './ScreenLink.module.scss';

const GetElement = ({ type, text }) => {
  const { formatMessage: t } = useIntl();
  switch (type) {
    case 'title':
      return <h3>{t({ id: text })}</h3>;
    case 'paragraph':
      return <p>{t({ id: text })}</p>;
    default:
      return null;
  }
};

const ScreenLink = ({ linkData }) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: t } = useIntl();

  return (
    <>
      <Modal
        isVisible={isVisible}
        close={hideModal}
        contentLabel={linkData.title}
        headerText={t({ id: 'equipment_types.label' })}
      >
        <Grid>
          {linkData.content.map(({ key, image, description }, i) => {
            return (
              <Row key={key} className={s.row}>
                <Col md={12} lg={6}>
                  <img src={image.src} height="500px" alt="" />
                </Col>
                <Col md={12} lg={6}>
                  {description.map((el) => (
                    <GetElement key={`${el.type}_${i}`} {...el} />
                  ))}
                </Col>
              </Row>
            );
          })}
        </Grid>
      </Modal>
      <button onClick={showModal} className={cn('link', s.showModalBtn)} type="button">
        <OutlineQuestionCircle />
      </button>
    </>
  );
};

export default ScreenLink;
