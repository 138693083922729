/* eslint-disable prefer-rest-params */
import ReactGA from 'react-ga';
import settings from 'config/settings';

export default function initGA() {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', settings.ga);

  ReactGA.initialize(settings.ga);
}
