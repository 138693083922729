import TagManager from 'react-gtm-module';
import settings from 'config/settings';

export default function initGTM() {
  const tagManagerArgs = {
    gtmId: settings.gtmId,
  };

  TagManager.initialize(tagManagerArgs);
}
