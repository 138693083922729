import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import Cookiebot from 'react-cookiebot';
import TrackPage from 'components/common/TrackPage';
import { LocaleContextProvider } from 'contexts/LocaleContext';
import IntlProviderWrapper from 'contexts/IntlProviderWrapper';
import { AppContextProvider } from 'contexts/AppContext';
import settings from './config/settings';
import RootRouter from './router/RootRouter';
import startup from './startup';
import apolloClient from './graphql/apollo';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'rc-dropdown/assets/index.css';
import './styles/index.scss';
import 'normalize.css';

startup();

const App = () => (
  <ApolloProvider client={apolloClient}>
    <LocaleContextProvider>
      <IntlProviderWrapper>
        <AppContextProvider>
          <RootRouter>
            <TrackPage />
          </RootRouter>
          <Cookiebot domainGroupId={settings.cookiebotDomainGroupId} />
        </AppContextProvider>
      </IntlProviderWrapper>
    </LocaleContextProvider>
    <ToastContainer />
  </ApolloProvider>
);

export default App;
