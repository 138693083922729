import React from 'react';
import useLogin from 'hooks/auth/useLogin';
import { trackLogin } from 'utils/analytics';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import LoginForm from 'components/auth/LoginForm';
import { useLocation } from 'react-router-dom';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { QuickCalculatorStep1 } from 'assets/icons/QuickCalculator';
import { AUTH_WITH_FACEBOOK_MUTATION, AUTH_WITH_GOOGLE_MUTATION } from 'hooks/auth/useAuthWithThirdPartyService';
import useAuth from 'hooks/auth/useAuth';
import { GOOGLE_SERVICE } from 'constants/variables';
import s from './LoginTwoFA.module.scss';

const LoginTwoFA = () => {
  const location = useLocation();
  const service = get(location, 'state.user.service', null);
  const projectId = get(location, 'state.user.projectId', '');
  const email = get(location, 'state.user.email', '');
  const plainPassword = get(location, 'state.user.plainPassword', '');

  const loginWithPassword = useLogin();
  const [loginWithService] = useAuth(
    service?.name === GOOGLE_SERVICE ? AUTH_WITH_GOOGLE_MUTATION : AUTH_WITH_FACEBOOK_MUTATION,
  );

  const handleSubmit = useAuthFormSubmit(!service ? loginWithPassword : loginWithService, {
    onSuccess: trackLogin,
  });

  const initialValues = { email, plainPassword, otp: '' };
  const onSubmit = React.useCallback(
    async (values, formikActions) => {
      await handleSubmit({ ...values, projectId, ...service }, formikActions);
    },
    [handleSubmit, projectId, service],
  );

  return (
    <Grid className={s.container}>
      <Row className={s.row}>
        <Col md={5} className={s.form}>
          <PageTitle>
            <FormattedMessage id="auth.login_page_title" />
          </PageTitle>
          <LoginForm
            isAdmin
            isLoginTwoFA
            isLoginTwoFAWithService={service}
            initialValues={initialValues}
            signUpEnabled={false}
            onSubmit={onSubmit}
          />
        </Col>
        <Col md={6}>
          <QuickCalculatorStep1 width="100%" height="100%" />
        </Col>
      </Row>
    </Grid>
  );
};

export default LoginTwoFA;
