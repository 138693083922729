import React, { useMemo, useRef } from 'react';
import NumberInput from 'components/inputs/NumberInput';
import { Formik, Form, Field } from 'formik';
import { BudgetStep6 } from 'assets/icons/budget';
import * as yup from 'yup';
import Button from 'components/common/Button';
import map from 'lodash/map';
import { Row, Col } from 'react-flexbox-grid';
import ScrollToError from 'components/common/ScrollToError';
import { useIntl } from 'react-intl';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { __ } from 'utils/form';

function getValidationSchema(initialValues = []) {
  return yup.object().shape({
    ...initialValues.reduce((acc, key) => {
      const currentField = {
        [key]: yup
          .number()
          .nullable()
          .required(__('errors.fill_field')),
      };
      return { ...acc, ...currentField };
    }, {}),
  });
}

const LifestyleEditMonthlyExpensesForm = ({ initialValues, onSubmit, monthlyExpensesKeys }) => {
  const { formatMessage: t } = useIntl();
  const validationSchema = useMemo(() => getValidationSchema(Object.keys(initialValues)), [initialValues]);
  const formikRef = useRef();

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <Formik
          innerRef={formikRef}
          {...{ initialValues, validationSchema }}
          onSubmit={(...props) => onSubmit(...props, formikRef.current)}
        >
          {({ isSubmitting }) => (
            <Form>
              <ScrollToError />
              {map(initialValues, (_, key) => {
                const field = monthlyExpensesKeys.find(({ key: fieldKey }) => key === fieldKey);
                return (
                  <Field
                    key={key}
                    name={key}
                    component={NumberInput}
                    required
                    max={10000}
                    label={`${t({ id: field.label })}, €`}
                  />
                );
              })}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={routePaths.lifestyleOverview}>
                  <Button color="outline" disabled={isSubmitting} startIcon={<ArrowBack />}>
                    {t({ id: 'app.back' })}
                  </Button>
                </Link>
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                  {t({ id: 'app.save' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={0} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep6 width="100%" height="100%" title={t({ id: 'alt_text.budget_edit_monthly_expenses_img' })} />
        </div>
      </Col>
    </Row>
  );
};

export default LifestyleEditMonthlyExpensesForm;
