import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Settings, Pencil, ArrowForward } from 'assets/icons';
import { ReactComponent as CloudDownloadIcon } from 'assets/icons/cloud-download.svg';
import { Grid, Row, Col } from 'react-flexbox-grid';
import NegativeMonthlyRateBudgetStep from 'components/budget/NegativeMonthlyRateBudgetStep';
import Alert from 'components/common/Alert';
import PageTitle from 'components/common/PageTitle';
import Tooltip from 'components/common/Tooltip';
import MonthlyExpensesInfo from 'components/budget/MonthlyExpensesInfo';
import AffordableLoanInfo, { PotentialAffordableLoanInfo } from 'components/budget/AffordableLoanInfo';
import MonthlyNetIncomeInfo from 'components/budget/MonthlyNetIncomeInfo';
import routePaths, { routePatterns } from 'router/route-paths';
import CircleButton from 'components/common/CircleButton';
import Button from 'components/common/Button';
import { SectionWrapper } from 'components/common/heap';
import { useIntl } from 'react-intl';
import MonthlyExpensesChart from 'components/budget/charts/MonthlyExpensesChart';
import MonthlyIncomeChart from 'components/budget/charts/MonthlyIncomeChart';
import { Hidden, Visible } from 'components/common/Grid';
import { animateScroll } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import useLastProjectId from 'hooks/project/useLastProjectId';
import useMyBudget from 'hooks/budget/useMyBudget';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { get } from 'lodash';
import LifestyleCalculator from './LifestyleCalculator';
import s from './LifestyleOverview.module.scss';

function MonthlyRateMessage({ monthlyRate, budget: { calculation } }) {
  const potentialAmountOfLoan = get(calculation, 'potential_amount_of_loan.calculated_value');
  const potentialMonthlyRateLoan = get(calculation, 'potential_monthly_rate_loan.calculated_value');
  const monthlyRateLoan = get(calculation, 'monthly_rate_loan.calculated_value');
  const amountOfLoan = get(calculation, 'amount_of_loan.calculated_value');

  const myBudget = { potentialAmountOfLoan, potentialMonthlyRateLoan, monthlyRateLoan, amountOfLoan };

  if (monthlyRate < 0) {
    return (
      <>
        <Alert color="danger">
          <NegativeMonthlyRateBudgetStep negativeMonthlyRateLoan={Math.abs(monthlyRate)} />
        </Alert>
        <Alert className={s.alertPotentialValues}>
          {potentialAmountOfLoan && potentialMonthlyRateLoan ? (
            <PotentialAffordableLoanInfo {...{ myBudget }} divider={false} />
          ) : null}
        </Alert>
      </>
    );
  }
  return <AffordableLoanInfo {...{ myBudget }} />;
}

export const LifestyleOverview = ({ myBudget, projectId, monthlyRate }) => {
  const { formatMessage: t } = useIntl();
  const monthlyRateMessageRef = useRef();
  const { lastProjectId } = useLastProjectId();

  useEffect(() => {
    if (monthlyRateMessageRef.current) {
      const coefficient = isMobile ? 140 : 100;
      const coordinates = monthlyRateMessageRef.current.getBoundingClientRect();
      animateScroll.scrollTo(coordinates.top + window.pageYOffset - coefficient);
    }
  }, []);

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <div className={s.header}>
            <PageTitle>{t({ id: 'page_titles.my_budget' })}</PageTitle>
            <div className={s.actionBar}>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_download_button' })}</p>} placement="left">
                <div className="mr-2">
                  <a href={myBudget.pdf} target="_blank" rel="noopener noreferrer" className="link">
                    <CircleButton className={s.pdfBtn}>
                      <CloudDownloadIcon />
                    </CircleButton>
                  </a>
                </div>
              </Tooltip>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_edit_button' })}</p>} placement="left">
                <div className="mr-2">
                  <Link to={routePaths.lifestyleCalculatorEditValues}>
                    <CircleButton>
                      <Pencil />
                    </CircleButton>
                  </Link>
                </div>
              </Tooltip>
              <Tooltip overlay={<p>{t({ id: 'budget.tooltip_settings_button' })}</p>} placement="left">
                <div>
                  <Link to={routePaths.lifestyleCalculator}>
                    <CircleButton>
                      <Settings />
                    </CircleButton>
                  </Link>
                </div>
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>

      <Hidden xl xxl>
        <Row>
          <Col xl={6}>
            <div ref={monthlyRateMessageRef}>
              <MonthlyRateMessage {...{ monthlyRate, budget: myBudget }} />
            </div>
          </Col>
        </Row>
      </Hidden>

      <Row>
        <Col xl={6} className={s.table}>
          <Visible xl xxl>
            <div ref={monthlyRateMessageRef}>
              <MonthlyRateMessage {...{ monthlyRate, budget: myBudget }} />
            </div>
          </Visible>
          <BudgetSummary budget={myBudget} />

          <div className={s.btnWrapper}>
            <Link to={routePaths.lifestyleCalculatorEditValues}>
              <Button color="outline" endIcon={<Pencil />}>
                {t({ id: 'budget.btn_label_to_budget_edit' })}
              </Button>
            </Link>
            {!projectId && !lastProjectId ? (
              <Link to={routePaths.newProject}>
                <Button endIcon={<ArrowForward />}>{t({ id: 'my_projects.new_project' })}</Button>
              </Link>
            ) : (
              <Link to={routePatterns.loan.stringify({ id: projectId || lastProjectId })}>
                <Button endIcon={<ArrowForward />}>{t({ id: 'budget.btn_label_to_financing' })}</Button>
              </Link>
            )}
          </div>
        </Col>
        <Col xl={6} first="xs" last="xl">
          <MonthlyExpensesChart budget={myBudget} />
          <div className="my-4">
            <MonthlyIncomeChart budget={myBudget} />
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

function BudgetSummary({ budget }) {
  return (
    <>
      <div className={s.tablesWrapper}>
        <SectionWrapper>
          <MonthlyNetIncomeInfo budget={budget} />
          <div className={s.divider} />
        </SectionWrapper>
        <SectionWrapper>
          <MonthlyExpensesInfo budget={budget} />
        </SectionWrapper>
      </div>
    </>
  );
}

const LifestyleOverviewContainer = () => {
  const [myBudget, loading] = useMyBudget();

  if (loading) return <LoadingOverlay />;
  if (!myBudget || !myBudget.finished) return <LifestyleCalculator />;

  const monthlyRate = get(myBudget, 'calculation.monthly_rate_loan.calculated_value', null);

  return <LifestyleOverview {...{ myBudget, monthlyRate }} />;
};

export default LifestyleOverviewContainer;
