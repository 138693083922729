import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { PROPERTY_VALUATION } from 'constants/variables';
import useProjectBudgetQuery from 'hooks/budget/useProjectBudgetQuery';
import { get } from 'lodash';
import LifestyleCalculatorForm from 'components/budget/form';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getInitialLifestyleCalculatorValues } from './LifestyleCalculator';
import { LifestyleOverview } from './LifestyleOverview';

const LifestyleCalculatorWithProject = () => {
  const { id: projectId } = useParams();
  const { myBudget, project, loading } = useProjectBudgetQuery(projectId);
  const { formatMessage: t } = useIntl();

  const formValues = get(project, 'form_values', {});
  const { living_space, apartment_area, house_area } = formValues;

  const projectType = useMemo(() => (project?.type !== PROPERTY_VALUATION ? project?.type : null), [project]);
  const squareMeters = useMemo(() => [living_space, apartment_area, house_area, 0].find((v) => Number.isFinite(v)), [
    apartment_area,
    house_area,
    living_space,
  ]);

  const initialValues = useMemo(
    () => ({
      project_type: projectType,
      square_meters: squareMeters,
      ...getInitialLifestyleCalculatorValues(myBudget),
    }),
    [myBudget, squareMeters, projectType],
  );
  const finished = useMemo(() => (!myBudget ? false : myBudget.finished), [myBudget]);
  const initialStep = useMemo(() => (myBudget && !finished ? 5 : 1), [myBudget, finished]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  const monthlyRate = get(myBudget, 'calculation.monthly_rate_loan.calculated_value', null);

  if (!myBudget || !myBudget.finished) return <LifestyleCalculatorForm {...{ initialValues, finished, initialStep }} />;

  return <LifestyleOverview {...{ projectId, myBudget, monthlyRate, t }} />;
};

export default LifestyleCalculatorWithProject;
